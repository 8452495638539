export const tspList=["ALL", "AIRTEL", "VI", "BSNL", "JIO" ]
export let arry = {
 
  CELL_ID: "cell_id",
    IP_ADDRESS: "ip_port",
    MOBILE_NUMBER: "multiple_mobile",
    IMEI_NUMBER: "imei_number",
    ILD: "ild",
  };
  export let requestProvide=[ "CDR", "IPDR", "TOWER_DUMP", "CAF"]

 export const firType = [
    { label: "FIR no.", value: "Fir" },
    { label: "Complaint no.", value: "complaint" },
    { label: "GD no.", value: "GD_NO" },
    { label: "E-FIR", value: "E_FIR" },
    { label: "LR", value: "LR" },
    { label: "MPR", value: "MPR" },
    { label: "NCR", value: "NCR" },
    { label: "Court Case / Legal Notice", value: "COURT_CASE_OR_LEGAL_NOTICE" },
    { label: "Other", value: "other" },
  ];
 export const firTypeList = [
    { label: "FIR no.", value: "Fir" },
    { label: "Complaint no.", value: "complaint" },
    { label: "GD no.", value: "GD_NO" },
    { label: "E-FIR", value: "E_FIR" },
    { label: "LR", value: "LR" },
    { label: "MPR", value: "MPR" },
    { label: "NCR", value: "NCR" },
    { label: "Court Case / Legal Notice", value: "COURT_CASE_OR_LEGAL_NOTICE" },
   
  ];

