export const LOGIN = "/account/login/";
export const REGISTRATION = "/account/register/";
export const OTP_SEND = "/account/send-otp/";
export const OTP_VERIFY = "/account/otp-verified/";

export const GET_DISTRICT = "/common/districts/";
export const GET_POLICE_STATION_LIST = "/common/police-stations/";
export const GET_STATES = "/common/states/";
export const USER_DETAIL = "/account/me/";
export const FORM_REQUEST = "/form/request/";
export const REJECT_REQUEST = "/form/reject-forms/";
export const APPROVE_REQUEST = "/form/approve-decisions/";
export const VIEW_ATTACHMENTS="/form/request-attachments/"
export const MAKE_PDF="/form/make-pdf/"
export const TSP_LIST="/common/tsps/"
export const REQUEST_TO_PROVIDE="/common/request-to-provides/"
export const TARGET_TYPE="/common/target-types/"
export const CASE_TYPE="/common/case-types/"
export const FORM_OTP_VERIFY="/form/otp-verified/"
export const RESEND_FORM_OTP="/form/resend-otp/"
export const DCP_PASSWORD_VERIFY="/form/check-mail/"
export const CHANGE_PASSWORD="/account/register/"
export const EXPORT_DCP_FILE="/form/request/export_excel/"
export const REFRESH="/token/refresh/"
