import React from "react";
import SearchBar from "../../components/searchComponent/searchBar";

function HomePage() {
  return (
    <div className="w-full bg-black h-full p-4">
      <div className="flex items-center justify-center ">
        <h2 className="text-[16px] text-[white] p-4">DISTRICT HOME PAGE</h2>
      </div>
      <div className="bg-[#2d3c53] text-black rounded">hello</div>
    </div>
  );
}
export default HomePage;
